import { Page, Layout, TextContainer, Text } from '@shopify/polaris'
import { SendOwlBulk } from './SendOwlBulk'
import { SyncSearch } from './SyncSearch'
import { User } from './User'
import { Log } from './Log'
import { ClearAWSCache } from './ClearAWSCache'
import React, { useEffect, useContext, useState, useCallback } from 'react'
import { Context } from '@shopify/app-bridge-react'

function App() {
  const [state, setState] = useState({
    user: '',
    env: process.env.NODE_ENV,
    apiEndpoint: process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://helper.winds-score.com',
    wsEndpoint: process.env.NODE_ENV === 'development' ? 'ws://localhost:8080' : 'wss://helper.winds-score.com',
  })
  const app = useContext(Context)

  useEffect(() => {
    localStorage.getItem('user') && setState({ ...state, user: localStorage.getItem('user') })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeUser = useCallback((v) => {
    setState({ ...state, user: v })
    if (v) {
      localStorage.setItem('user', v)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title={`WindsScore支援ツール（開発中）`}>
      <Layout>
        <Layout.Section>
          <TextContainer>
            <Text variant="bodyMd" as="p">
              Shopifyの運用を助ける支援ツール郡です。Google Cloud
              Platform上でshopify-helperというアプリとして稼働しています。
              <br />
              何か不具合ありましたらSlackの&nbsp;
              <a href="https://windsscore.slack.com/archives/C010Z4T4FAP" target="_blank" rel="noreferrer">
                #team-システムエンジニアリング
              </a>
              &nbsp; まで。
            </Text>
          </TextContainer>
        </Layout.Section>
        <User user={state.user} onChange={changeUser} />
        <div
          style={{
            width: '100%',
            pointerEvents: state.user !== '' ? 'all' : 'none',
            opacity: state.user !== '' ? 1.0 : 0.2,
          }}
        >
          <ClearAWSCache app={app} state={state} />
        </div>
        <div
          style={{
            width: '100%',
            pointerEvents: state.user !== '' ? 'all' : 'none',
            opacity: state.user !== '' ? 1.0 : 0.2,
          }}
        >
          <SendOwlBulk app={app} state={state} />
        </div>
        <div
          style={{
            width: '100%',
            pointerEvents: 'none',
            opacity: 0.2,
          }}
        >
          <SyncSearch state={state} />
        </div>
        <div
          style={{
            width: '100%',
            pointerEvents: state.user !== '' ? 'all' : 'none',
            opacity: state.user !== '' ? 1.0 : 0.2,
          }}
        >
          <Log app={app} state={state} />
        </div>
      </Layout>
    </Page>
  )
}

export default App
