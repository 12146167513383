import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import '@shopify/polaris/build/esm/styles.css'
import jaTranslations from '@shopify/polaris/locales/ja.json'
import { AppProvider } from '@shopify/polaris'
import { Provider, Loading } from '@shopify/app-bridge-react'
import './index.css'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const config = {
  apiKey: 'abe11c419b40135aeb4b7c5da7a102ce',
  host: new URLSearchParams(window.location.search).get('host'),
  forceRedirect: true,
}

const root = ReactDOM.createRoot(document.getElementById('root'))

if (process.env.NODE_ENV === 'development') {
  root.render(
    <AppProvider i18n={jaTranslations}>
      <App />
    </AppProvider>,
  )
} else {
  root.render(
    <AppProvider i18n={jaTranslations}>
      <Provider config={config}>
        <Loading />
        <App />
      </Provider>
    </AppProvider>,
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
