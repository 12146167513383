import React from 'react'
import { Layout, Stack, TextField } from '@shopify/polaris'

export const User = (props) => {
  return (
    <Layout.Section>
      <Stack vertical>
        <div style={{ width: 360 }}>
          <TextField
            label="作業者名を自由形式で入力してください(ログ記録用)"
            autoComplete="off"
            value={props.user}
            onChange={(v) => {
              props.onChange(v)
            }}
            placeholder="minami"
            maxLength={16}
          />
        </div>
      </Stack>
    </Layout.Section>
  )
}
