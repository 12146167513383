import React, { useCallback, useState } from 'react'
import { Layout, Stack, Card, Button, Text, Form, FormLayout, ButtonGroup, TextField } from '@shopify/polaris'
import { getSessionToken } from '@shopify/app-bridge-utils'
import axios from 'axios'

export const SendOwlBulk = (props) => {
  const { app, state } = props
  const [value, setValue] = useState('')
  const handleSubmit = useCallback(async () => {
    let token = 'dummytoken'
    if (app) {
      token = await getSessionToken(app)
    }
    const data = value
      .split('\n')
      .map((v) => v.trim())
      .filter((v) => v.length > 0)
    axios({
      url: `${state.apiEndpoint}/admin/sendowl/create`,
      method: 'post',
      data: { skus: data, user: state.user },
      headers: { 'x-wsadmin-api-key': `${token}` },
    })
  }, [app, value, state.apiEndpoint, state.user])
  const clearValue = useCallback(() => {
    setValue('')
  }, [])

  return (
    <Layout.Section>
      <Card sectioned>
        <Stack vertical>
          <Text variant="heading2xl" as="h2">
            SendOwlバルク登録
          </Text>
          <Text variant="bodyMd" as="p">
            SKUを改行区切りで貼り付けて送信することで、SendOwlに商品をまとめて登録できます。
            <br />
            事前準備として、
            <a
              href="https://drive.google.com/drive/u/1/folders/11ltuOmiFkXPkBntg0uXQ63NenEUzA-wk"
              target="_blank"
              rel="noreferrer noopener"
            >
              Google Driveの所定のフォルダ
            </a>
            に [SKU].pdf または [SKU].mp3 を配置してください。
          </Text>
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <TextField
                label="SendOwlに登録するSKUの一覧"
                multiline={4}
                maxHeight={600}
                autoComplete="off"
                value={value}
                onChange={(v) => {
                  setValue(v)
                }}
                placeholder="DL-SAMPLE-00001
DL-SAMPLE-00001-01
DL-SAMPLE-00001-02
..."
              />

              <ButtonGroup>
                <Button submit primary>
                  送信
                </Button>
                <Button onClick={clearValue}>クリア</Button>
              </ButtonGroup>
            </FormLayout>
          </Form>
        </Stack>
      </Card>
    </Layout.Section>
  )
}
