import React, { useCallback, useState, useEffect } from 'react'
import { Layout, Card, TextField, Button, FormLayout } from '@shopify/polaris'
import { getSessionToken } from '@shopify/app-bridge-utils'
import axios from 'axios'

export const Log = (props) => {
  const { app, state } = props
  const [ws, setWs] = useState(null)
  const [messages, setMessages] = useState([])

  const sendToken = useCallback(async () => {
    if (!ws) return
    let token = 'dummytoken'
    if (app) {
      token = await getSessionToken(app)
    }
    ws.send(`auth ${token}`)
  }, [app, ws])

  const addWsListneres = useCallback(() => {
    if (!ws) return
    ws.onopen = async () => {
      sendToken()
    }
    ws.onmessage = (event) => {
      setMessages((prevMessages) => [event.data, ...prevMessages])
    }
    window.setInterval(async () => {
      // 3分ごとにトークンを送信して認証を更新する
      sendToken()
    }, 1000 * 60 * 3)
  }, [ws, sendToken])

  useEffect(() => {
    const f = async () => {
      const w = new WebSocket(`${state.wsEndpoint}/admin/message`)
      setWs(w)
    }
    f()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!ws) return
    addWsListneres()
  }, [ws, addWsListneres])

  return (
    <Layout.Section>
      <Card sectioned>
        <FormLayout>
          <TextField
            label="サーバーログ"
            multiline={20}
            maxHeight={600}
            autoComplete="off"
            value={messages.join('\n')}
            disabled={true}
            placeholder="サーバーのログが流れます"
            id="logconsole"
          />
          <Button
            onClick={async () => {
              let token = 'dummytoken'
              if (app) {
                token = await getSessionToken(app)
              }
              axios({
                url: `${state.apiEndpoint}/admin/hello`,
                method: 'get',
                headers: { 'x-wsadmin-api-key': `${token}` },
              })
            }}
          >
            ログ送受信確認ボタン
          </Button>
        </FormLayout>
      </Card>
    </Layout.Section>
  )
}
